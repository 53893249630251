import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  category?: string
  className?: string
  discount?: string
  image?: ImageProps
  languageCode: string
  style?: any
  title: string
  URL: string
  validity?: string
}

export const Slide = memo(function Slide({
  category,
  className,
  discount,
  image,
  languageCode,
  style,
  title,
  URL,
  validity,
}: Props) {
  if (!title || !URL) {
    return null
  }

  return (
    <Container className={className} style={style} to={URL}>
      <Image>
        {image ? <LazyLoadImage effect="blur" {...image} /> : null}
        {validity ? <Validity>{validity}</Validity> : null}
      </Image>
      <Wrapper>
        {category ? <Category>{category}</Category> : null}
        <Title>{title}</Title>
        {discount ? (
          <Discount dial={4} row>
            {discount}
          </Discount>
        ) : null}
        <CTA
          className="offer-cta"
          label={useVocabularyData('find-out', languageCode)}
          noActions
          variant="dark"
        />
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  &:hover {
    .offer-cta {
      color: ${rgba(theme.colors.variants.neutralLight4, 0.5)};
      &:after {
        flex: 1;
      }
    }
  }

  @media (max-width: 1023px) {
    align-items: flex-start;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`

const Image = styled.div`
  width: 38%;
  height: calc(100% + 3.75rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  margin-top: -3.75rem;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    backface-visibility: hidden;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.7)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.25s ease-in-out;
    z-index: 2;
  }

  img {
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 50%;
    height: 100%;
    margin-top: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 40%;
  }
`

const Validity = styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  line-height: 2rem;
  padding: 0 3.333vw;
  position: absolute;
  bottom: 1.875rem;
  left: 0;
  text-align: center;
  z-index: 2;

  @media (max-width: 1023px) {
    padding: 0 1.875rem;
  }
`

const Wrapper = styled.div`
  width: 62%;
  padding: 3.5625rem 4.722vw 0;

  @media (max-width: 1023px) {
    width: 50%;
    padding: 3.75rem 1.875rem 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 60%;
    padding-top: 1.875rem;
  }
`

const Category = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  margin-bottom: 0.875rem;
  text-transform: uppercase;
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.5625rem;
`

const Discount = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 2rem;
  margin-top: 0.875rem;
  text-transform: lowercase;
  &:before {
    content: '';
    width: 5.694vw;
    max-width: 5.125rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: 1.25rem;
    transform: translateY(0.1875rem);
  }
`

const CTA = styled(Button)`
  margin-top: 2.75rem;

  @media (max-width: 374px) {
    display: none;
  }
`
