import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useRef, useState } from 'react'

import { Props as SlideProps, Slide } from './Slide'

const AUTOPLAY_TIMER = 5000

export interface Props {
  languageCode: string
  offersButtonURL?: string
  slides: SlideProps[]
  title?: string
}

export const OffersSlider = memo(function OffersSlider({
  languageCode,
  offersButtonURL,
  slides,
  title,
}: Props) {
  if (slides.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)
  const [opacities, setOpacities] = React.useState<number[]>([])
  const timer = useRef<any>()

  const [sliderRef, instanceRef] = useKeenSlider({
    drag: false,
    loop: slides.length > 1 ? true : false,
    slides: slides.length,
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      const newOpacities = s.track.details.slides.map((slide) => slide.portion)
      slides.length > 1 ? setOpacities(newOpacities) : null
    },
  })

  useEffect(() => {
    timer.current = setInterval(() => {
      if (instanceRef) {
        instanceRef.current?.next()
      }
    }, AUTOPLAY_TIMER)

    return () => {
      clearInterval(timer.current)
    }
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <Title>
        {title ? title : useVocabularyData('look-other-offers', languageCode)}
      </Title>
      <Wrapper>
        <Slider ref={sliderRef}>
          {slides.map((item, index) => (
            <Slide
              key={index}
              style={{
                opacity: opacities[index],
                zIndex: opacities[index],
              }}
              {...item}
            />
          ))}
        </Slider>
        {slides.length > 1 ? (
          <Dots dial={2} row>
            {slides.map((item, index) => (
              <Dot
                key={index}
                className={currentSlide === index ? 'active' : undefined}
                onClick={() => {
                  instanceRef.current?.moveToIdx(index)
                }}
              />
            ))}
          </Dots>
        ) : null}
        {offersButtonURL ? (
          <CTA to={offersButtonURL}>
            {useVocabularyData('all-offers', languageCode)}
          </CTA>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem 6.667vw 6rem;

  @media (max-width: 1023px) {
    margin: 7.5rem 1.875rem 5rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.5625rem;
  padding-right: 8.75vw;
  text-align: right;

  @media (max-width: 1023px) {
    padding-right: 0;
    text-align: center;
  }
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.variants.primaryDark1};
  margin-top: 1.75rem;
  padding: 0 7.361vw 1.875rem;
  text-align: center;

  @media (max-width: 1023px) {
    padding: 1.875rem;
  }

  @media (max-width: 767px) {
    padding: 0 0 1.875rem;
  }
`

const Slider = styled.div`
  display: flex;
  min-height: 36vh;
  position: relative;

  @media (max-width: 767px) {
    height: 60vh;
    min-height: 0;
  }
`

const Dots = styled(FlexBox)`
  margin: 3rem auto 0;

  @media (max-width: 767px) {
    margin-top: 0;
  }
`

const Dot = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.3;
  margin: 0 0.625rem;
  &:first-of-type {
    margin-top: 0;
  }
  &.active {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 1;
    pointer-events: none;
  }
`

const CTA = styled(Link)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0875rem;
  line-height: 1.25rem;
  margin: 3.75rem auto 0;
  padding-bottom: 0.25rem;
  position: relative;
  text-transform: uppercase;
  &:hover {
    &:after {
      right: -0.375rem;
      left: -0.375rem;
    }
  }
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    bottom: -0.25rem;
    right: 50%;
    left: 50%;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    &:after {
      right: -0.375rem;
      left: -0.375rem;
    }
  }

  @media (max-width: 767px) {
    margin-top: 3rem;
  }
`
